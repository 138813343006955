<template>
    <div class="app-body">
        <span class="a-fs-16 a-ptb-6 a-fw-700">待办事项</span>
        <div class=" todolist a-flex-rsbfs a-fs-14" style="align-items:stretch">
            <el-card class="todolist-left a-flex-cfsfs">
                <div class="todolist-left-item" @click="pageParam.params.keyType = item.key"
                    :class="{ 'active': pageParam.params.keyType == item.key }"
                    v-for="(item, index) in todonum.todolist" :key="item.key">{{ item.title }} ({{ item.num }})</div>

            </el-card>
            <el-card class="todolist-right a-ml-16 el-main">
                <el-form >
                    <el-form-item label="处理状态">
                        <el-select v-model="pageParam.params.isDeal" placeholder="请选择状态">
                            <el-option label="已处理" :value="1"></el-option>
                            <el-option label="待处理" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <le-pagview ref="todolist" @setData="setTableData" :pageParam="pageParam">
                    <el-table :data="tableData" :highlight-current-row="true" style="width: 100%">
                        <el-table-column prop="title" label="事项名称" width="200">
                        </el-table-column>
                        <el-table-column prop="content" label="内容" width="600">
                        </el-table-column>
                        <el-table-column prop="opUserName" label="提交人">

                        </el-table-column>
                        <el-table-column prop="createTimeText" label="提交时间" width="200">

                        </el-table-column>
                        <el-table-column label="状态" width="150">
                            <template slot-scope="{ row }">
                                <el-tag>{{ row.status }}</el-tag>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作" align="center" fixed="right">
                            <template slot-scope="{ row }">
                                <el-tooltip class="item" effect="dark" content="去处理" placement="top">
                                    <div @click="handlerGoDetail(row)" class="a-flex-rcc a-w-100">
                                        <span class="a-c-blue a-w-100" style="text-align:center"
                                            :class="row.pcNotifyPage ? 'a-cursor-p' : 'a-default-p'">去处理</span>
                                        <!-- <img src="../../assets/icon/option-detail.png" class="a-wh-16" /> -->
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </le-pagview>
            </el-card>

        </div>
    </div>

</template>
<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
export default {
    data() {
        return {
            pageParam: {
                url: this.$Config.apiUrl.getWithDoneList,
                method: "post",
                params: {
                    keyType: "",
                    isDeal: 0
                },
                freshCtrl: 1,
            },
            tableData: [],
        }
    },
    created() {

    },
    computed: {
        ...mapState({
            todonum: state => state.todonum.todonum
        })
    },
    watch: {
        'pageParam.params': {
            handler(val) {
                this.handlerSearch()
            },
            deep: true
        }
    },
    activated () {
        this.pageParam.freshCtrl++;
    },
    methods: {
        setTableData(data) {
            this.tableData = data;
        },
        handlerSearch() {
            this.$refs['todolist'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        handlerGoDetail(obj) {
            if (obj.pcNotifyPage && obj.pcNotifyPageParam) {
                this.$router.push({
                    path: obj.pcNotifyPage,
                    query: JSON.parse(obj.pcNotifyPageParam)
                })
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.todolist {
    height: 100%;

    &-left {
        // height: 80vh;

        height: calc(100% - 80px);

        /deep/ .el-card__body {
            padding: 0;
            width: 100%;
            padding-bottom: 50px;
        }

        width: 184px;
        padding: 16px 8px;

        &-item {
            height: 40px;
            line-height: 40px;
            padding-left: 16px;
            border-radius: 4px;
            width: 100%;

            &:hover {
                font-weight: bold;
                // background: #EBF0F5;
            }

            &.active {
                font-weight: bold;
                background: #EBF0F5;
            }
        }
    }

    &-right {
        height: calc(100% - 80px);
    }
}

/deep/ .searchToo {
    display: none;
}
</style>